import Rails from "rails-ujs";
import Turbolinks from "turbolinks";
import Foundation from "foundation-sites";
import $ from "jquery";
import * as ActiveStorage from "activestorage";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

import "application.scss";

import "images/facebook-cover.png";
import "images/arrow.svg";
import "images/logo.svg";
import "images/logo-footer.svg";
import "images/logo-footer-mobile.svg";
import "images/tw.svg";
import "images/lin.svg";
import "images/fb.svg";
import "images/in.svg";
import "images/tw-green.svg";
import "images/lin-green.svg";
import "images/fb-green.svg";
import "images/in-green.svg";
import "images/apl.png";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
Foundation.addToJquery($);

$(document).on("turbolinks:load", function() {
  $(this).foundation();

  if ($("[data-slick='testimonials']").length > 0) {
    $("[data-slick='testimonials']").slick({
      arrows: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      adaptiveHeight: true
    });
  }

  if ($("[data-slick='process']").length > 0) {
    $("[data-slick='process']").slick({
      arrows: true,
      dots: false,
      slidesToShow: 1,
      asNavFor: "[data-slick='process-image'], [data-slick='process-count']",
      responsive: [
        {
          breakpoint: 640,
          settings: {
            adaptiveHeight: true
          }
        }
      ]
    });
  }

  if ($("[data-slick='process-image']").length > 0) {
    $("[data-slick='process-image']").slick({
      arrows: false,
      dots: false,
      slidesToShow: 1,
      asNavFor: "[data-slick='process'], [data-slick='process-count']"
    });
  }

  if ($("[data-slick='process-count']").length > 0) {
    $("[data-slick='process-count']").slick({
      arrows: false,
      dots: false,
      vertical: true,
      slidesToShow: 1,
      verticalSwiping: true,
      asNavFor: "[data-slick='process'], [data-slick='process-image']"
    });
  }

  if ($("[data-slick='project-gallery']").length > 0) {
    $("[data-slick='project-gallery']").slick({
      arrows: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 6000,
      slidesToShow: 1
    });
  }
});
